// Talktime
import Particles from './tools/particles';
import Forms from './tools/forms';

let onDocumentReady = () => {
    Particles();
    Forms();
};

if('addEventListener' in window) window.addEventListener('DOMContentLoaded', onDocumentReady);
