let options = {
    banner     : "banner",
    blocks     : "blocks",
    url        : "../scripts/json/particles.json"
};

const Particles = () => {
    if (document.getElementById(options.banner) !== null) {
        particlesJS.load(options.banner, options.url);
    } 
    if (document.getElementById(options.blocks) !== null) {
        particlesJS.load(options.blocks, options.url);
    } 
};

export default Particles;