
let forms = document.getElementsByClassName('needs-validation');

const Prevent = (event) => {
    event.preventDefault();
    event.stopPropagation();
};

const Validate = () => {
    Array.prototype.filter.call(forms, (form) => {
        form.addEventListener('submit', (event) => {
            if(form.checkValidity() === false) { Prevent(event); }
            form.classList.add('was-validated');
        }, false);
    }, false);
}

const Forms = () => {
    Validate();
};

export default Forms;